














import { Vue, Component } from 'vue-property-decorator'
import pkg from '../package.json'

@Component({})
export default class App extends Vue {
  pkg = pkg

  mounted () {
    document.title = this.pkg.title
  }
}
