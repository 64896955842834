











































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Patcher from '@/modules/patcher'

@Component({})
export default class Main extends Vue {
  flashStoreOriginal: File | null = null
  flashStoreMod: File | null = null
  flashCarOriginal: File | null = null

  isFlashesCorrect = false
  isFlashBuilding = false

  errorText = ''

  @Watch('flashStoreOriginal')
  watchFlashStoreOriginal (): void {
    this.checkFlashes()
  }

  @Watch('flashStoreMod')
  watchFlashStoreMod (): void {
    this.checkFlashes()
  }

  @Watch('flashCarOriginal')
  watchFlashCarOriginal (): void {
    this.checkFlashes()
  }

  async checkFlashes (): Promise<void> {
    if (!this.flashStoreOriginal || !this.flashStoreMod || !this.flashCarOriginal) {
      this.isFlashesCorrect = false
      return
    }
    const dataStoreOriginal = new Uint8Array(await this.flashStoreOriginal.arrayBuffer())
    const dataStoreMod = new Uint8Array(await this.flashStoreMod.arrayBuffer())
    const dataCarOriginal = new Uint8Array(await this.flashCarOriginal.arrayBuffer())
    const data = [dataStoreOriginal, dataStoreMod, dataCarOriginal]
    if (!Patcher.checkSameSize(data)) {
      this.errorText = 'Размер прошивок не совпадает'
      this.isFlashesCorrect = false
      return
    }
    this.errorText = ''
    this.isFlashesCorrect = true
  }

  downloadFile (file: Blob): void {
    const url = URL.createObjectURL(file)
    const domLink = document.createElement('a')
    domLink.setAttribute('href', url)
    domLink.setAttribute('download', 'car-mod.bin')
    domLink.click()
  }

  async patch (storeOriginal: File, storeMod: File, carOriginal: File): Promise<Blob> {
    const dataStoreOriginal = new Uint8Array(await storeOriginal.arrayBuffer())
    const dataStoreMod = new Uint8Array(await storeMod.arrayBuffer())
    const dataCarOriginal = new Uint8Array(await carOriginal.arrayBuffer())
    const dataCarMod = Patcher.patch(dataStoreOriginal, dataStoreMod, dataCarOriginal)
    return new Blob([dataCarMod], {
      type: 'application/octet-stream'
    })
  }

  async downloadFlash (): Promise<void> {
    if (!this.flashStoreOriginal || !this.flashStoreMod || !this.flashCarOriginal) {
      return
    }
    this.isFlashBuilding = true
    const blobCarMod = await this.patch(this.flashStoreOriginal, this.flashStoreMod, this.flashCarOriginal)
    this.downloadFile(blobCarMod)
    this.isFlashBuilding = false
  }
}
