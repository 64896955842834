export default class Patcher {
  // Check for signature
  public static checkSignature (data: Uint8Array): boolean {
    const sign = Uint8Array.from([
      0x9A, 0x9A, 0x9A, 0x9A, 0x9B, 0x9B, 0x9B, 0x9B, 0x5C, 0x5C, 0x5C, 0x5C, 0x5D, 0x5D, 0x5D, 0x5D,
      0x00, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF
    ])
    if (data.length < sign.length) return false
    return !!sign
      .map((b, i) => Number(data[i] === b))
      .reduce((buf, v) => buf * v)
  }

  // Check that all data has same size
  public static checkSameSize (dataList: Uint8Array[]): boolean {
    if (dataList.length < 1) return true
    const size = dataList[0].length
    return !!dataList
      .map(data => Number(data.length === size))
      .reduce((buf, v) => buf * v)
  }

  // Returns position of different bytes
  public static findDiffs (data1: Uint8Array, data2: Uint8Array): number[] {
    const diffs = [] as number[]
    data1.forEach((_, i) => {
      if (data1[i] !== data2[i]) diffs.push(i)
    })
    return diffs
  }

  // Patches car flash by changes from store flash
  public static patch (storeOriginal: Uint8Array, storeMod: Uint8Array, carOriginal: Uint8Array): Uint8Array {
    const diffs = this.findDiffs(storeOriginal, storeMod)
    const carMod = Uint8Array.from(carOriginal)
    diffs.forEach(pos => {
      carMod[pos] = storeMod[pos]
    })
    return carMod
  }
}
